import React, { useEffect, lazy, Suspense, useState } from "react";
import * as config from '../helpers/configuration';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Utility from '../helpers/Utility';
import { Helmet } from 'react-helmet';
const PSucursal = lazy(() => import('./PSucursal'));
const PContacto = lazy(() => import('./PContacto'));
const PagGracias = lazy(() => import('./PagGracias'));
const PAvisoPrivacidad = lazy(() => import('./PAvisoPrivacidad'));
const PTermiyCondi = lazy(() => import('./PTermiyCondi'));
const PDondePagar = lazy(() => import('./PDondePagar'));
const PAliados = lazy(() => import('./PAliados'));
const Carrucelb = lazy(() => import('../Componentes/Carrucelb'));
const Footers = lazy(() => import('../Componentes/Footers'));
const PreguntasF = lazy(() => import('../Componentes/PreguntasF'));
const Carrucela = lazy(() => import('../Componentes/Carrucela'));
const Blog = lazy(() => import('../Componentes/Blog/Blog'));
const DetailBlog = lazy(() => import('../Componentes/Blog/DetailBlog'));
const PPersonas = lazy(() => import('./PPersonas'));
const NotFound = lazy(() => import('../NotFound/NotFound'));

let utility = new Utility();
const referral = new URLSearchParams(window.location.search).get("referral");

export default function Menu() {
  useEffect(() => {
    window.onload = () => {
      if (window.location.hash && window.location.hash.split('#')[1] && document.getElementById(window.location.hash.split('#')[1])) {
        document.getElementById(window.location.hash.split('#')[1]).scrollIntoView();
      }
    }
    document.onclick = (e) => {
      if (!e.target.closest('.dropdown-toggle') && document.querySelector('.navbar-collapse.show')) {
        document.querySelector('.navbar-collapse.show').classList.remove("show");
      }
    }
  }, [])

  const [Blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    await utility.crud.get("getBlogs?type=Merchant").then(data => {
      setBlogs(data);
    }).catch((error) => {

    })
  }


  try {
    return (
      <div>
        <Router>
          <header className="bg-mens">
            <nav className="navbar navbar-expand-md navbar-dark ">
              <div className="container"> <a className="navbar-brand" href="/"><img className="contm2"
                src="/imagen/img-logotipoo.png"
                srcSet='/imagen/img-logotipoo-480w.png 480w,/imagen/img-logotipoo.png 800w'
                sizes="(max-width: 600px) 480px,800px" alt="" height={"100%"} /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon" /> </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-">
                    <li className="nav-item dropdown d-flex">
                      <Link className="nav-link" to="/" style={{ paddingRight: "0px" }}>PACIENTES</Link>
                      <a className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown" href="/" role="button" aria-expanded="false"></a>
                      <ul className="dropdown-menu bg-opacity-50">
                        <li><Link className="dropdown-item col-text-men" to="/">¿Qué es Cha'Pay?</Link></li>
                        <li><a className="dropdown-item col-text-men" href="/#funciona-info">¿Cómo funciona?</a></li>
                        <li><a className="dropdown-item col-text-men" href="/#como-info">¿Cómo puedo pagar?</a></li>
                        <li><a className="dropdown-item col-text-men" href="/#donde-info">¿Dónde puedo pagar?</a> </li>
                        <li><a className="dropdown-item col-text-men" href="/#preguntas-info">Preguntas frecuentes</a></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown d-flex">
                      <Link className="nav-link" to="/Aliados" style={{ paddingRight: "0px" }}>ALIADOS</Link>
                      <a className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown" href="/Aliados" role="button" aria-expanded="false"></a>
                      <ul className="dropdown-menu bg-opacity-50">
                        <li> <Link className="dropdown-item col-text-men" to="/Aliados">¿Qué es Cha'Pay?</Link> </li>
                        <li> <a className="dropdown-item col-text-men" href="Aliados#como-info">¿Cómo funciona?</a> </li>
                        <li> <a className="dropdown-item col-text-men" href="Aliados#bene-info">Beneficios</a> </li>
                        <li> <a className="dropdown-item col-text-men" href="Aliados#preguntas-info">Preguntas frecuentes</a> </li>
                      </ul>
                    </li>
                    <li className="nav-item"> <Link className="nav-link" to="/Reddealiados">RED DE ALIADOS</Link></li>
                    <li className="nav-item"> <Link className="nav-link" to="/Blog">BLOG</Link></li>
                    <li className="nav-item visible nav-link">|</li>
                    <li className="nav-item"> <a className="nav-link" href={config.APPURL + "/cuenta" + (referral?"?referral="+referral:"")}>MI CUENTA</a> </li>
                  </ul>
                  <a className="btn-verde" href={config.APPURL + "/cuenta/usuario"+ (referral?"?referral="+referral:"")}>REGISTRO PACIENTES</a>
                  <a className="btn-azul-claro" href={config.APPURL + "/cuenta/comercio"+ (referral?"?referral="+referral:"")}>REGISTRO NEGOCIOS</a> </div>
              </div>
            </nav>
          </header>


          <div>
            <Suspense fallback={null}>
              <Switch>
                <Route exact path="/preguntas-info">
                  <Redirect to={"/#preguntas-info"} />
                </Route>
                <Route exact path="/">
                  <Person />
                </Route>
                <Route path="/Aliados">
                  <Aliados />
                </Route>
                <Route path="/Reddealiados">
                  <DondePagar />
                </Route>
                <Route path="/Gracias">
                  <PaginaGracias />
                </Route>
                <Route path="/Contacto">
                  <Contacto />
                </Route>


                <Route path="/Sucursales/:SucursalesId">
                  <Sucursales />
                </Route>
                <Route path={["/Blog"]}
                  exact
                  render={(routeProps) =>
                    <>
                      <Blog {...routeProps} getBlogs={getBlogs} Blogs={Blogs} />
                      <Footers />
                    </>
                  }>
                </Route>
                <Route path={["/Blog/:BlogId"]}
                  exact
                  render={(routeProps) =>
                    <>
                      <DetailBlog {...routeProps} />
                      <Footers />
                    </>
                  }>
                </Route>
                <Route path="/TerminosYCondiciones">
                  <TerminosYCondiciones />
                </Route>
                <Route path="/Privacidad">
                  <Privacidad />
                </Route>

                <Route component={NotFound}></Route>




              </Switch>
            </Suspense>
          </div>
        </Router>
      </div>
    );
  }
  catch (error) {
    console.log(error)
    return (<NotFound />)
  }
}


function Person() {
  return (
    <div>
      <Carrucela referral={referral}/>
      <PPersonas referral={referral}/>
      <PreguntasF referral={referral}/>
      <Footers />
    </div>
  );
}

function Aliados() {
  return (
    <div>
      <Carrucelb referral={referral}/>
      <PAliados referral={referral}/>
      <PreguntasF referral={referral}/>
      <Footers />
    </div>
  );
}

function DondePagar() {
  return (
    <div>
      <PDondePagar />
      <Footers />
    </div>
  );
}
function PaginaGracias() {
  return (
    <div>
      <PagGracias />
      <Footers />
    </div>
  );
}
function Contacto() {
  return (
    <div>
      <PContacto />
      <Footers />
    </div>
  );
}

function Sucursales() {
  return (
    <div>
      <PSucursal />
      <Footers />
    </div>
  );
}



function TerminosYCondiciones() {
  return (
    <div>
      <PTermiyCondi />
      <Footers />
    </div>
  );
}

function Privacidad() {
  return (
    <div>
      <PAvisoPrivacidad />
      <Footers />
    </div>
  );
}

