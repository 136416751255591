export const API = "https://api.chapay.mx/"//"http://localhost:4000/"//"https://api.chapay.mx/";//"http://localhost:4000/" "https://api.chapay.mx/";
export const APPURL = "https://app.chapay.mx";
export const GCAPTCHA = "6Le1QdseAAAAAC0H8DgyGMZwUO4enVo8So6mGxMQ";
export const GKEY = "AIzaSyBf-6_rsp5WnK9zDIcyRAB6jIYIHF8Lb-g";
export const partners = [{
    link: "https://www.bayer.com/en/?autotranslate=es",
    src: "imagen/partners/Bayer.webp",
    height:"150px"
},
{
    link: "https://actipulseneuroscience.com/",
    src: "imagen/partners/Actipulse_Logo.webp",
    height:"113px"
},
{
    link: "https://www.grupomh.mx/",
    src: "imagen/partners/Logo-GMH.webp",
    height:"62px"
},
{
    link: "https://medicadelaciudad.com/",
    src: "imagen/partners/Medical_De_la_Ciudad.webp",
    height:"48px"
},
{
    link: "https://hospitalesmac.com/",
    src: "imagen/partners/MAC.webp",
    height:"128px"
},
{
    link: "https://cmhac.com/",
    src: "imagen/partners/logo_cmh.webp",
    height:"90px"
}
]