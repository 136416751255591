import * as configuration from './configuration';

let urlPrefix = configuration.API;
let token = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.tyh-VfuzIxCyGYDlkBA7DfyjrqmSHu6pQ2hoZuFqUSLPNY2N0mpHb3nk5K17HWP_3cYHBw7AhHale5wky6-sVA";

class CRUD {

    post = (url, data, options) => {
        options = !options ? {} : options;
        options.headers = options.headers ? {
            ...options.headers, ...{
                Authorization: 'Bearer ' + token
            }
        } : {
            Authorization: 'Bearer ' + token
        }
        return fetch(urlPrefix + url, {
            method: 'POST',
            headers: options.headers,
            body: data
        }).then((data) => {
            return data.json()
        })
    }

    get = async (url, options) => {
        options = !options ? {} : options;
        options.headers = {
            Authorization: 'Bearer ' + token
        }
        return await fetch(urlPrefix + url, {
            method: 'GET',
            headers: options.headers
        }).then((data) => {
            return data.json()
        })
    }
}

class customActions {

    /* for email validaiton */
    isEmailValid = (value) => {
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (value === "") {
            return false;
        } else {
            if (value.match(mailformat)) {
                return true;
            } else {
                return false;
            }
        }
    }

    /* for phone no validation */
    isPhoneNoValid = (value) => {
        var phoneNoFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (value === "") {
            return false;
        } else {
            if (value.match(phoneNoFormat)) {
                return true;
            } else {
                return false;
            }
        }
    }


}


export default class Utility {
    crud = new CRUD();
    customActions = new customActions();
}